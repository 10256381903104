
$main: #27774a;
$dark: #063c1d;
$neon: #73ff25;
$contrast: #ffbf00;
$background: #d4e8dc;

body {
  margin: 0;
  background-color: $background;
}

.App {
  font-family: sans-serif;
  font-size: 16px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.column {
  min-width: 400px;
  max-width: 1000px;
  &.preview {
    flex-grow: 1;
    min-width: 1000px;
  }
}

.main-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.preview-output {
  font-size: 10px;
  background-color: white;
  border-radius: 3px;
  min-height: 200px;
  margin: 0;
  padding: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

section {
  margin-bottom: 50px;
}

header {
  width: 100%;
  height: 90px;
  margin-bottom: 50px;
  padding-bottom: 20px;
  border-bottom: thin solid $main;
  h1 {
    margin: 0 0 10px;
    color: $dark;
  }
  .mode {
    color: $main;
    margin: 0;
    font-size: 32px;
    font-weight: 500;
    display: flex;
  }
}

label, input {
  display: block;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
}

label {
  color: $dark;
  &.inline-block {
    display: inline-block;
  }
}

input {
  border: 0;
  border-radius: 3px;
}

.ucfirst {
  text-transform: capitalize;
}

.button {
  border: none;
  border-radius: 3px;
  font-size: 16px;
  padding: 5px 15px;

  &--action {
    background-color: $main;
    color: white;
    margin-left: 20px;
  }

  &--main {
    background-color: $dark;
    color: $neon;
    font-size: 20px;
    padding: 10px 30px;
    border-radius: 6px;
    text-transform: uppercase;
  }

  &--start-nav {
    width: 100%;
    margin-bottom: 20px;
    text-align: left;
    position: relative;
    &:after {
      display: block;
      content: "➡";
      font-size: 38px;
      position: absolute;
      top: 0;
      right: 20px;
    }
  }

  &--disabled {
    background-color: grey;
    color: black;
  }

  &--margin-left {
    margin-left: 20px;
  }
}

.startbalance {
  font-size: 16px;
  font-weight: 500;
  padding: 10px;
  margin: 10px 0;
  text-align: right;
  width: 200px;
}

.result {
  font-family: "Roboto Mono";
  text-align: left;
  margin: 20px auto;
  display: block;
  border: thin solid green;
  border-radius: 3px;
  padding: 10px;
}
.result p {
  line-height: 1.5;
}
